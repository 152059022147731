<template>
  <!-- <a-modal
    @cancel="handledClose('close')"
    @ok="handledClose('ok')"
  > -->
  <a-row type="flex" class="model-row">
    <a-col :span="12" class="model-col model-left">
      <div class="left-title" v-if="!isPopup">请选择用户</div>
      <!-- search -->
      <div class="model-search">
        <a-input
          class="model-input"
          v-model:value.trim="seachVal"
          :placeholder="placeholder"
          @change="toChange"
          allow-clear
        >
          <template #prefix>
            <SearchOutlined style="color: rgba(0, 0, 0, 0.25)" />
          </template>
        </a-input>
      </div>
      <!-- 面包屑 -->
      <div class="model-breadcrumb">
        <a-breadcrumb>
          <template #separator><span style="color: black">></span></template>
          <a-breadcrumb-item
            href=""
            v-for="(item, index) in breadcrumbList"
            @click="handledBreadcrumb(item, index)"
            :key="index"
          >
            <a-tooltip placement="topRight" :title="item.label" v-if="item.label.length > 7">
              {{ item.label }}
            </a-tooltip>
            <div v-else>
              {{ item.label }}
            </div>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <!-- 全选 -->
      <!-- <span
          :class="[
            'all-sel',
            {
              'all-sel-disabled': isSelectedFlag()
            }
          ]"
          @click="toAllSel"
          v-if="checkAll"
        >
          全选
        </span> -->
      <!-- 待选择列表 -->
      <div class="left-list">
        <template v-if="renderList.length">
          <div v-for="item in renderList" :key="item.id" class="model-item">
            <a-checkbox :checked="getCheckState(item)" class="model-checkbox" @change="handledChange(item)">
              <div class="model-checkbox-item">
                <img class="model-checkbox-img" :src="item.icon" />
                <div class="model-checkbox-name">
                  <a-tooltip placement="topLeft" :title="item.dataName" v-if="item.dataName.length > 8">
                    <div :class="['ellipsis', item.islower ? 'max-12' : item.mobile ? 'max-6' : 'max-200']">
                      {{ item.dataName }}
                    </div>
                    <text v-if="item.mobile">（{{ item.mobile }}）</text>
                  </a-tooltip>
                  <div v-else style="display: flex;">
                    <div :class="['ellipsis', item.islower ? 'max-12' : item.mobile ? 'max-6' : 'max-200']">
                      {{ item.dataName }}
                    </div>
                    <text v-if="item.mobile">（{{ item.mobile }}）</text>
                  </div>
                </div>
              </div>
            </a-checkbox>
            <div
              v-if="item.islower"
              :class="getCheckState(item) ? 'model-lower-level-disable' : 'model-lower-level'"
              @click.stop="tolowerMdel(item.departmentId, item, '')"
            >
              <span class="model-title">下级</span>
              <RightOutlined :style="getCheckState(item) ? { color: '#B8B8B8' } : { color: '#C3161C' }" />
            </div>
          </div>
        </template>
        <a-empty v-else :image="simpleImage" />
      </div>
    </a-col>
    <!-- 已选列表 -->
    <a-col :span="12" class="model-col model-right">
      <div class="flex-layout" style="justify-content: space-between">
        <h4 v-if="!isPopup">已选择用户</h4>
        <h4 v-else>已选：{{ checkedStaff.length }} 个</h4>
        <span class="checked-progress" v-if="checkAllLimit">{{ checkedStaff.length }}/{{ checkAllLimit }}</span>
      </div>
      <div class="model-checked" v-if="checkedStaff.length">
        <a-row v-for="(item, index) in checkedStaff" :key="item.id" class="model-checked-row">
          <a-col :span="22" style="padding-right: 20px; display: flex" class="single-wrap">
            <img class="model-checked-img" :src="item.icon" />
            <div class="model-checkbox-name">
              <a-tooltip placement="topLeft" :title="item.dataName" v-if="item.dataName.length > 8">
                <div :class="['ellipsis', item.islower ? 'max-12' : item.mobile ? 'max-6' : 'max-200']">
                  {{ item.dataName }}
                </div>
                <text v-if="item.mobile">（{{ item.mobile }}）</text>
              </a-tooltip>
              <div v-else style="display: flex">
                <div :class="['ellipsis', item.islower ? 'max-12' : item.mobile ? 'max-6' : 'max-200']">
                  {{ item.dataName }}
                </div>
                <text v-if="item.mobile">（{{ item.mobile }}）</text>
              </div>
            </div>
          </a-col>
          <a-col :span="2" @click="handledDelete(item, index)" class="model-close">
            <CloseCircleFilled />
          </a-col>
        </a-row>
      </div>
      <a-empty v-else :image="simpleImage" style="margin-top: 150px" />
    </a-col>
  </a-row>
  <!--  -->
</template>
<script>
import { reactive, toRefs, onMounted, ref, onUnmounted, defineComponent, watchEffect, watch } from 'vue'
import { RightOutlined, CloseCircleFilled, SearchOutlined } from '@ant-design/icons-vue'
import { getSearchData, getDepartmentOrStaff } from '@/apis/businessManage'
import { Empty } from 'ant-design-vue'
import { useStore } from 'vuex'
import { cmsNotice } from '@/utils/utils'
export default defineComponent({
  name: 'RangeModal',
  props: {
    isPopup: {
      type: Boolean,
      default: false
    },
    selectData: {
      type: Array,
      default: () => []
    },
    // 全选
    checkAll: {
      type: Boolean,
      default: false
    },
    checkAllLimit: {
      required: false,
      type: Number
    },
    noPermissionControl: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CloseCircleFilled,
    SearchOutlined,
    RightOutlined
  },
  emits: ['checkData'],
  setup(props, { emit }) {
    const state = reactive({
      seachVal: '', // 搜索关键词
      checkedStaff: [],
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      renderList: [],
      breadcrumbList: []
    })
    const store = useStore()
    const time = ref(null)
    const placeholder = ref('请输入员工姓名或部门')
    const selectorType = ref(1)

    // 获取左list单项选中状态，判断右列表是否存在该数据
    const getCheckState = item => {
      return state.checkedStaff.findIndex(it => it.dataType === item.dataType && it.dataId === item.dataId) >= 0
    }

    // 切换选中状态，在右列表中删掉或增加数据，选中状态还是根据 getCheckState() 判断
    const handledChange = e => {
      const checked = getCheckState(e)
      console.log('选中的', checked, state.renderList)
      if (checked) {
        const index = state.checkedStaff.findIndex(it => it.dataType === e.dataType && it.dataId === e.dataId)
        state.checkedStaff.splice(index, 1)
        emit('checkData', [...state.checkedStaff])
      } else {
        if (props.checkAllLimit && state.checkedStaff.length === props.checkAllLimit)
          return cmsNotice('warning', '当前已添加至人员上限请删除已选人员后重试')
        console.log('e', e)
        state.checkedStaff.push({
          dataName: e.dataType == 1 ? e.dataName : e.dataName + `（${e.members}人）`,
          dataType: e.dataType,
          dataId: e.dataId,
          icon: e.icon,
          mobile: e.mobile,
          islower: e.islower
        })
        emit('checkData', [...state.checkedStaff])
      }
    }
    const handledBreadcrumb = (e, i) => {
      state.breadcrumbList.splice(i + 1, state.breadcrumbList.length - i)
      if (e.label === store.state.user.userInfo.result.enterpriseName) {
        getScopeList()
        return
      }
      tolowerMdel(e.id, '', 'jump')
    }
    const handledDelete = (e, i) => {
      state.checkedStaff.splice(i, 1)
      emit('checkData', [...state.checkedStaff])
    }
    //搜索
    const toChange = e => {
      if (time.value) {
        clearTimeout(time.value)
      }
      time.value = setTimeout(() => {
        let data = e.target.value
        console.log('搜索输入内容data', data)
        state.breadcrumbList.splice(1, state.breadcrumbList.length)
        if (data === '') {
          getScopeList()
          return
        }
        searchData(data)
      }, 800)
    }
    //搜索请求
    const searchData = async data => {
      let params = {
        selectorType: selectorType.value,
        searchName: data,
        allData: props.noPermissionControl
      }

      const res = await getSearchData(params)
      console.log('搜索到的值res', res)
      handelData(res)
    }
    // 确定or取消
    const handledClose = type => {
      state.seachVal = ''
      type === 'ok' && emit('checkData', [...state.checkedStaff])
    }
    //初始列表
    const getScopeList = async data => {
      let params = {
        selectorType: selectorType.value, //1部门人员2部门3员工
        allData: props.noPermissionControl
      }
      // console.log('拼接后的参数', { ...params, ...data })
      const res = await getDepartmentOrStaff({ ...params, ...data })
      if (res.success) {
        handelData(res)
      }
    }
    // 部门子级
    const tolowerMdel = async (id, val, flg) => {
      // 已选中部门，子集禁用
      if (val != '' && getCheckState(val)) return
      let data = {
        departmentId: id
      }
      getScopeList(data)
      // const res = await getscopelower({ departmentId: id })
      // handelData(res)
      if (flg === 'jump') return
      state.breadcrumbList.push({
        label: val.departmentName,
        id: val.departmentId
      })
    }

    // 处理左侧数据
    const handelData = res => {
      let department = []
      let personnel = []
      if (res.data.departmentList && res.data.departmentList.length !== 0) {
        department = res.data.departmentList.map(item => {
          item.islower = true
          // item.islower = item.hasChildren //0没有下级,1有下级
          item.dataName = item.departmentName
          item.dataType = 2
          item.dataId = item.departmentId
          item.members = item.members ?? 0
          item.icon = require('@/assets/images/checkStaff/level.png')
          return item
        })
      }
      if (res.data.staffList && res.data.staffList.length !== 0) {
        personnel = res.data.staffList.map(item => {
          item.islower = false
          // item.islower = item.hasChildren //0没有下级,1有下级
          item.dataName = item.staffName
          item.dataType = 1
          item.dataId = item.staffId
          item.icon = item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')
          // item.identity = item.identity
          return item
        })
      }

      if (!props.isPopup) {
        //角色管理页
        personnel = personnel.filter(it => it.identity === 2) //过滤超管【1-超管；2-普通员工】
      }

      // state.renderList = department.concat(personnel)
      state.renderList = [...department, ...personnel]
      console.log('左侧数据', state.renderList)
    }
    const getPopupContainer = trigger => {
      return trigger.parentElement
    }

    const isAvatar = item => {
      if (item.icon) return item.icon
      if (item.fullAvatar) return item.fullAvatar
      if (item.dataType === 2) return require('@/assets/images/checkStaff/level.png')
      return require('@/assets/images/default-avatar.png')
    }

    // props.selectData.forEach(item => {
    //   state.checkedStaff.push({
    //     dataName: item.dataName,
    //     dataType: item.dataType,
    //     dataId: item.dataId,
    //     icon: isAvatar(item)
    //   })
    // })

    // 全选
    const toAllSel = () => {
      const flag = isSelectedFlag()
      console.log(flag)
      if (flag) return
      const limitNum = props.checkAllLimit // 限制
      // 没有个数限制 权限
      if (props.checkAll && !limitNum) {
        state.renderList.forEach((e, index) => {
          if (e.islower || getCheckState(e)) return
          state.checkedStaff.push({
            dataName: e.dataName,
            dataType: e.dataType,
            dataId: e.dataId,
            icon: e.icon,
            mobile: e.mobile,
            islower: e.islower
          })
        })
        return
      }
      // 个数限制
      const checkedStaffLength = state.checkedStaff.length //  已选的长度
      checkedStaffLength === limitNum && cmsNotice('warning', '当前已添加至人员上限请删除已选人员后重试')
      var personelIndex = 0 //为员工的个数
      if (checkedStaffLength >= limitNum) return
      state.renderList.forEach((e, index) => {
        if (e.islower || getCheckState(e)) return
        personelIndex++
        if (personelIndex <= limitNum - checkedStaffLength) {
          state.checkedStaff.push({
            dataName: e.dataName,
            dataType: e.dataType,
            dataId: e.dataId,
            icon: e.icon,
            mobile: e.mobile,
            islower: e.islower
          })
        }
      })
    }

    const isSelectedAll = (arr1, arr2) => {
      const arrs = []
      arr2.forEach(item => {
        const data = arr1.find(i => i.dataId === item.dataId)
        data && arrs.push(data)
      })
      return arr2.length ? arrs.length === arr1.length : false
    }
    const isSelectedFlag = () => {
      const flag = props.checkAllLimit
        ? state.checkedStaff.length === props.checkAllLimit
        : isSelectedAll(
            state.renderList.filter(it => it.dataType === 1),
            state.checkedStaff
          )
      return flag
    }

    const getList = () => {
      // 获取数据
      state.seachVal = ''
      state.renderList = []
      state.checkedStaff = []
      state.breadcrumbList = [{ label: store.state.user.userInfo.result.enterpriseName }]

      props.selectData.forEach(item => {
        state.checkedStaff.push({
          dataName: item.dataName,
          dataType: item.dataType,
          dataId: item.dataId,
          icon: isAvatar(item),
          mobile: item.mobile,
          islower: item.islower
        })
      })
      getScopeList()
    }

    onMounted(() => {
      getList()
    })
    onUnmounted(() => {
      clearTimeout(time.value)
    })
    watch(
      () => props.selectData,
      () => {
        state.checkedStaff = []
        console.log('sle', props.selectData)
        props.selectData.forEach(item => {
          state.checkedStaff.push({
            dataName: item.dataName,
            dataType: item.dataType,
            dataId: item.dataId,
            icon: isAvatar(item),
            mobile: item.mobile,
            islower: item.islower
          })
        })
      }
    )

    return {
      ...toRefs(state),
      getCheckState,
      handledChange,
      toChange,
      handledBreadcrumb,
      handledDelete,
      handledClose,
      tolowerMdel,
      getPopupContainer,
      placeholder,
      toAllSel,
      isSelectedAll,
      isSelectedFlag,
      selectorType,
      getList
    }
  }
})
</script>

<style lang="less" scoped>
:deep(.ant-modal-header) {
  border-bottom: 1px solid red !important;
}

.model-row {
  // background: skyblue;
  // background: #f7f7f7;
  height: 362px;
  overflow: hidden;
  // margin: 20px 0 0 0;

  .model-col {
    .model-search {
      display: flex;

      .model-button {
        margin-left: 20px;
      }
    }

    .model-breadcrumb {
      margin-top: 8px;
    }

    .subtile {
      margin-top: 12px;
    }

    .left-list {
      margin-top: 8px;
      overflow: scroll;
      height: 256px;
      padding: 0 10px 0px 0;

      .model-lower-level {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #c3161c;
        cursor: pointer;
      }
      .model-title {
        margin-right: 12px;
        white-space: nowrap;
      }
      .model-lower-level-disable {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #b8b8b8;
        cursor: not-allowed;
      }

      .model-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .model-checkbox {
          flex: 1;
          // padding-right: 8px;
          display: flex;
          align-items: center;

          .model-checkbox-item {
            display: flex;
            align-items: center;

            .model-checkbox-img {
              width: 26px;
              height: 26px;
              border-radius: 50%;
              margin-left: 2px;
              margin-right: 8px;
            }

            .model-checkbox-name {
              cursor: pointer;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .model-checked {
      height: 326px;
      overflow-y: scroll;
      padding: 0 16px 10px 0;
      margin-top: 16px;
      // background-color: skyblue;

      .model-checked-row {
        margin-bottom: 20px;

        .model-close {
          text-align: right;
          color: #cccccc;
          width: 14px;
          height: 14px;
          cursor: pointer;
        }

        .model-checked-img {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          margin-right: 12px;
          vertical-align: middle;
        }
      }
    }
  }

  .model-left {
    padding: 0 14px 0 45px;
    // border: 2px solid yellow;
    border-right: 1px solid rgba(0, 0, 0, 0.06);

    .left-title {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 20px;
      margin-bottom: 16px;
    }
  }
  .model-search {
    :deep(span:first-child) {
      display: flex;
    }
  }

  .ellipsis {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .max-12 {
    max-width: 120px;
  }
  .max-6 {
    max-width: 74px;
  }
  .max-200 {
    width: 200px;
  }
  .model-right {
    padding: 0 24px 20px 24px;

    margin-bottom: 24px;
    overflow: hidden;
  }
  // .model-border {
  //   padding-left: 24px;

  // }

  :deep(.ant-breadcrumb a) {
    color: #333333;
    font-size: 12px;
    line-height: 12px;
    max-width: 110px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    direction: rtl;
  }

  :deep(.ant-breadcrumb > span:last-child a) {
    color: #c3161c;
    font-size: 12px;
  }
}

.all-sel {
  cursor: pointer;
  color: #2a68e7;
  margin: 5px 0;
  display: inline-block;
}

.all-sel-disabled,
.checked-progress {
  color: #969799;
}
</style>
